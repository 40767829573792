import type {
  SignInPayloadDTO,
  ApplyPasswordDTO,
  UpdatePasswordDTO,
} from "../types/authentication.dto";
import requests from "./axios";

const GrowerApi = {
  checkGrowerInfo: async (email: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/info?growerEmail=${email}`
    ),
  checkGrowerExist: async (growerRefId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/${growerRefId}/isExist`
    ),
  growerSignIn: async (payload: SignInPayloadDTO): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/signin`,
      payload
    ),
  growerSignUp: async (payload: any): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/signup?source=web`,
      payload
    ),
  addDefaultLocation: async (lat: number, lon: number): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/default-location?latitude=${lat}&longitude=${lon}`
    ),
  getCountriesList: async (): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/countries/?page=0&size=25&myfield-only=true`
    ),
  reSendEmail: async (email: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/email/resend-email?email=${email}&source=web`
    ),
  deleteAccount: async (growerRefId: string): Promise<any> =>
    await requests.del(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/${growerRefId}`,
      {}
    ),
  sendForgotPasswordEmail: async (email: string): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/forgot-password/?email=${email}&source=web`
    ),
  applyPassword: async (
    token: string,
    payload: ApplyPasswordDTO
  ): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/forgot-password/apply-password-change?token=${token}`,
      payload
    ),
  sendChangePasswordEmail: async (email: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/change-password/?email=${email}&source=web`
    ),
  updatePassword: async (
    token: string,
    payload: UpdatePasswordDTO
  ): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/update-password?token=${token}`,
      payload
    ),
};
export default GrowerApi;
