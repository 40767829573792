import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { ApplicationState } from "../../../core/redux/reducers";
import { setAnchorId } from "../../../core/redux/reducers/productTemplateSlice";
import { CONTACT_SYNGENTA_UK } from "../../../core/constants/url";
import ConstantKey from "../../../core/locale/strings.json";
import locationIcon from "../../../assets/icons/edit_location.svg";
import dashboardIcon from "../../../assets/icons/dashboard_icon.svg";
import mapviewIcon from "../../../assets/icons/mapview_icon.svg";
import myoffersIcon from "../../../assets/icons/myoffers_icon.svg";
import productsAndServicesIcon from "../../../assets/icons/productsandservices_icon.svg";
import contactsIcon from "../../../assets/icons/contacts_icon.svg";
import GetNow from "./getNow";
import "./sidebar.less";

interface RouteDTO {
  name: string;
  path: string;
  select: boolean;
  icon?: any;
}

const SidebarMenu: React.FC = () => {
  const history = useNavigate();
  const locations = useLocation();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const [mainMenu, setMainMenu] = useState<any>({
    dashboard: {
      name: ConstantKey.DASHBOARD,
      path: "/dashboard",
      select: false,
      icon: dashboardIcon,
    },
    mapview: {
      name: ConstantKey.MAP_VIEW,
      path: "/mapview",
      select: false,
      icon: mapviewIcon,
    },
    myoffers: {
      name: ConstantKey.MY_OFFERS,
      path: "/myoffers",
      select: false,
      icon: myoffersIcon,
    },
    productsandservices: {
      name: ConstantKey.NEW_PRODUCTS_AND_SERVICES,
      path: "/productsandservice",
      select: false,
      icon: productsAndServicesIcon,
    },
    contacts: {
      name: ConstantKey.CONTACT_SYNGENTA,
      path: "/contact",
      select: false,
      icon: contactsIcon,
    },
  });

  useEffect(() => {
    selectMenuItemOnClick(window.location.pathname);
  }, [window.location.pathname]);

  const selectMenuItemOnClick = (path: string): void => {
    dispatch(setAnchorId(null));
    const changeMainMenu: Record<string, RouteDTO> = {};
    Object.keys(mainMenu).forEach((ele: string) => {
      if (mainMenu[ele].path === path) {
        changeMainMenu[ele] = {
          ...mainMenu[ele],
          select: true,
        };
      } else {
        changeMainMenu[ele] = { ...mainMenu[ele], select: false };
      }
    });

    setMainMenu(changeMainMenu);
    if (path === "/productsandservice") {
      if (locations.pathname === "/dashboard")
        window.location.replace("/dashboard#productsandservice");
      else
        history("/dashboard", {
          state: { anchorId: "/dashboard#productsandservice" },
        });
    } else if (path === "/myoffers") {
      if (locations.pathname === "/dashboard")
        window.location.replace("/dashboard#myoffers");
      else
        history("/dashboard", {
          state: { anchorId: "/dashboard#myoffers" },
        });
    } else if (path === "/contact") window.open(CONTACT_SYNGENTA_UK, "_blank");
    else history(path);
  };
  const displayMenus = (menuItems: Record<any, RouteDTO>): JSX.Element => {
    return (
      <>
        {Object.keys(menuItems).map((ele: any, index) => {
          return (
            <div
              key={ele?.bannerRefId}
              className={`side-submenu-item ${
                menuItems[ele].select ? "sidebar-selected-background" : ""
              } `}
            >
              {menuItems[ele].select && (
                <span
                  className={`side-menu-selected ${
                    index === 0 ? "mf-ml-0" : ""
                  }`}
                ></span>
              )}
              <div
                className={`side-submenu-item ${
                  menuItems[ele]?.icon ? "side-submenu-item-icon-left" : ""
                }`}
                key={menuItems[ele].path}
              >
                {menuItems[ele].icon && (
                  <img
                    src={menuItems[ele].icon}
                    alt="icon"
                    className={`side-submenu-icon-size ${
                      menuItems[ele].select ? "sidebar-selected-background" : ""
                    }`}
                  />
                )}
                <span className="sidebar_margin_left_5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      selectMenuItemOnClick(menuItems[ele].path);
                    }}
                    className={`sidebar_button side-menu-text mf-cursor-pointer ${
                      menuItems[ele].select ? "side-menu-item-active" : ""
                    }`}
                  >
                    {translate(menuItems[ele].name)}
                  </button>
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="syt-antd-menu-item svg">
      <div className="side-menu-user">
        <span>
          <img src={locationIcon} alt="location" />
          {profileDataSelector?.userLocation
            ? profileDataSelector?.userLocation
            : "Location not found!"}
        </span>
      </div>
      <div className="get-now-menu-items">
        <GetNow />
      </div>
      <div className="side-menu-items">{displayMenus(mainMenu)}</div>
    </div>
  );
};

export default SidebarMenu;
