import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../core/redux/reducers/";
import mapviewGreenIcon from "../../assets/icons/mapview_green_icon.svg";
import greenArrow from "../../assets/icons/green_arrow.svg";

import ConstantKey from "../../core/locale/strings.json";
import "./dashboard.less";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "spraying-widget": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          lat: number;
          lon: number;
          source: string;
          language: string;
          "country-code": string;
          type: string;
          "crop-list": string;
          "default-crop": string;
          date: string;
          "data-source": string;
        },
        HTMLElement
      >;
    }
  }
}

const SprayClock = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigator = useNavigate();

  const { t: translate } = useTranslation();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    const todayDate = getCurrentDate();
    const countryCode =
      ProfileDataSelector?.profileData?.countryCode?.toUpperCase();
    const languageCode = ProfileDataSelector?.language;
    const lat = ProfileDataSelector?.defaultPropertyCoordinates?.lat;
    const lng = ProfileDataSelector?.defaultPropertyCoordinates?.lng;
    setUserData({
      todayDate,
      countryCode,
      languageCode,
      lat,
      lng,
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const widget: any = containerRef.current.querySelector("spraying-widget");
      if (widget?.shadowRoot) {
        const styles = `
          :host {
            height: 50px !important;
          }
          .circle-round-div {
            margin-top: 0px !important;
            margin-left: 0px !important;
            box-shadow: none !important;
          }
          .circle-round-div .circle-bottom-div {
            background-color: #ffffff !important;
            border-radius: 9px !important;
          }
          .circle-round-div .circle-top-div {
            display: none !important;
          }
          .circle-bottom-div .circle-left-div {
            display: none !important;
          }
          .circle-round-div .circle-bottom-div .circle-right-div {
            display: none !important;
          }
        `;

        const styleElement: any = widget.shadowRoot.querySelector("style");
        if (styleElement) {
          styleElement.textContent = `${styleElement.textContent}\n${styles}`;
        } else {
          const newStyleElement = document.createElement("style");
          newStyleElement.textContent = styles;
          widget.shadowRoot.appendChild(newStyleElement);
        }
      }
    }
  }, [userData]);
  const getCurrentDate = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const onClickButton = (): void => {
    navigator("/cw-hub-weather");
  };
  return (
    <div className="spray-clock-container" ref={containerRef}>
      {userData && (
        <spraying-widget
          lat={userData.lat}
          lon={userData.lng}
          source="weatherdata"
          language={userData.languageCode}
          country-code={userData.countryCode}
          type="spray"
          crop-list="sw_ground_frcp01|sw_groundsprayfungicide|sw_groundsprayherbicide_ld|sw_groundsprayherbicide_pe|sw_groundsprayherbicide_sd|sw_groundsprayvineandtree"
          default-crop="sw_groundsprayherbicide_pe"
          date={userData.todayDate}
          data-source="meteoblue"
        />
      )}
      <div className="spray-clock-des">
        {translate(ConstantKey.SPRAY_CLOCK_MSG)}
      </div>
      <Button className="spray-clock-button" onClick={onClickButton}>
        <img src={mapviewGreenIcon} alt="mapview green icon" />
        <span>{translate(ConstantKey.DETAILED_WEATHER_MAP)}</span>
        <img src={greenArrow} alt="green arrow" />
      </Button>
    </div>
  );
};

export default SprayClock;
