import React, { useId, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";

import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers";
import { setSyngentaProductsDetails } from "../../core/redux/reducers/homePageSlice";
import ConstantKey from "../../core/locale/strings.json";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { moreFromSyngentaTitleCTAClick } from "../../core/utils/analytics/constants";

import FarmInsignts from "../../assets/icons/FarmInsignts.svg";
import ContactSyngenta from "../../assets/icons/ContactSyngenta.svg";
import FieldInsights from "../../assets/icons/FieldInsights.svg";

import "./moresyngentatiles.less";

interface TileInfo {
  cropwiseLogo: string;
  productLogo: string;
  title: string;
  redirectURL: string;
  inAppRedirectUrl: boolean;
  poweredByTxt: string;
  amplitudeKey: string;
}

const MoreSyngentaTiles = (): JSX.Element => {
  const uid = useId();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const SyngentaProducts = useSelector(
    (state: ApplicationState) => state.homepage
  );
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode) {
      ProductTemplateApi.getTiles(
        ProfileDataSelector?.defaultLocation?.countryCode
      )
        .then((response: { status: number; data: TileInfo[] }) => {
          if (response.status === 200) {
            const res = [
              ...response.data,
              {
                title: "Farm Insights",
                poweredByTxt: "powered by",
                cropwiseLogo:
                  "https://myfield-templates.s3.eu-central-1.amazonaws.com/Cropwise+Logo.png",
                productLogo: FarmInsignts,
                redirectURL: "/mapview#farm-insights-info",
                inAppRedirectUrl: true,
                amplitudeKey: "on_more_field_monitoring_reports_click",
              },
              {
                title: "Field Insights",
                poweredByTxt: "powered by",
                cropwiseLogo:
                  "https://myfield-templates.s3.eu-central-1.amazonaws.com/Cropwise+Logo.png",
                productLogo: FieldInsights,
                redirectURL: "/mapview#field-insights-info",
                inAppRedirectUrl: true,
                amplitudeKey: "on_more_field_monitoring_reports_click",
              },
              {
                title: "Contact Syngenta",
                poweredByTxt: "powered by",
                cropwiseLogo:
                  "https://myfield-templates.s3.eu-central-1.amazonaws.com/Cropwise+Logo.png",
                productLogo: ContactSyngenta,
                redirectURL: "https://www.syngenta.co.uk/content/contact-us",
                amplitudeKey: "on_more_contact_syngenta_click",
              },
            ];
            dispatch(setSyngentaProductsDetails(res));
          }
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.defaultLocation?.countryCode]);

  const onClickTiles = (tileInfo: TileInfo): void => {
    const payload = {
      name: tileInfo?.title,
      category: tileInfo?.amplitudeKey,
    };
    sendAmplitudeData(moreFromSyngentaTitleCTAClick, payload);
    if (tileInfo?.inAppRedirectUrl) {
      if (tileInfo?.redirectURL === "internal/insightsReport") {
        history("/mapview", {
          state: { anchorId: "/mapview#insightsfromyourfarm" },
        });
      } else {
        history(tileInfo?.redirectURL.split("#")[0], {
          state: { anchorId: tileInfo?.redirectURL },
        });
      }
    } else {
      window.open(tileInfo.redirectURL, "_block");
    }
    sendAmplitudeData(tileInfo.amplitudeKey);
  };
  return (
    <AntdConfigProvider>
      <div className="grid-story-container">
        <div className="banner-title">
          {translate(ConstantKey.MORE_FROM_SYNGENTA)}
        </div>
        <Row align="top" justify="start" gutter={[16, 16]}>
          {SyngentaProducts?.syngentaProductsDetails?.map(
            (ele: any, i: number) => {
              return (
                <Col
                  offset={"0"}
                  order={i}
                  pull="0"
                  push="0"
                  key={uid}
                  onClick={() => {
                    onClickTiles(ele);
                  }}
                >
                  <div className="tiles-syngenta-layout">
                    <div>
                      <span className="tiles-title-text">
                        {ele.poweredByTxt}
                      </span>
                      <span>
                        <img
                          src={ele.cropwiseLogo}
                          className="tiles-title-icon"
                          alt="title icons"
                        />
                      </span>
                    </div>
                    <br />
                    <div>
                      <img src={ele.productLogo} alt="cropwise product" />
                    </div>
                    <div className="tiles-product-description mf-mt-10">
                      {translate(ele.title)}
                    </div>
                  </div>
                </Col>
              );
            }
          )}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default MoreSyngentaTiles;
