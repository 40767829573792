import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import appStore from "../../../assets/images/appstore.svg";
import googlePlay from "../../../assets/images/googleplay.svg";
import synMyFieldLogo from "../../../assets/images/synMyFieldLogo.svg";
import { APP_APPLE_URI, APP_GOOGLE_URL } from "../../../core/constants/url";

const GetNow = (): JSX.Element => {
  const onClickAppStore = (): void => {
    window.open(APP_APPLE_URI, "_blank");
  };
  const onClickGooglePlay = (): void => {
    window.open(APP_GOOGLE_URL, "_blank");
  };
  return (
    <div className="get-now-banner">
      <div className="mf-width-50">
        <img
          src={synMyFieldLogo}
          className="get-now-icon"
          alt="grower portal"
        />
      </div>
      <div className="get-now-buttons">
        <Button
          type={ButtonType.default}
          className="banner-button-white mf-mb-5"
          size="middle"
          onClick={onClickAppStore}
        >
          <img src={appStore} alt="app store" />
        </Button>
        <Button
          className="banner-button-white"
          size="small"
          onClick={onClickGooglePlay}
        >
          <img src={googlePlay} alt="google play store" />
        </Button>
      </div>
    </div>
  );
};
export default GetNow;
