import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../../core/locale/strings.json";
import { CONTACT_SYNGENTA_UK } from "../../../core/constants/url";
import dashboardIcon from "../../../assets/icons/dashboard_icon.svg";
import mapviewIcon from "../../../assets/icons/mapview_icon.svg";
import myoffersIcon from "../../../assets/icons/myoffers_icon.svg";
import productsAndServicesIcon from "../../../assets/icons/productsandservices_icon.svg";
import contactsIcon from "../../../assets/icons/contacts_icon.svg";

import "./sidebar.less";

const CollapseSidebar = (): JSX.Element => {
  const history = useNavigate();
  const locations = useLocation();
  const { t: translate } = useTranslation();
  const [selectedItem, setSelectedItem] = useState("" as string);

  useEffect(() => {
    const pathname = window.location.pathname?.split("/");
    if (pathname?.length >= 2) {
      setSelectedItem(pathname[1]);
    }
  }, [window.location.pathname]);

  const onClickItem = (item: string): void => {
    setSelectedItem(item);
    history(`/${item}`);
  };
  const onClickMyOffers = (item: string): void => {
    setSelectedItem(item);

    if (locations.pathname === "/dashboard")
      window.location.replace("/dashboard#myoffers");
    else
      history("/dashboard", {
        state: { anchorId: "/dashboard#myoffers" },
      });
  };
  const onClickProductsAndServices = (item: string): void => {
    setSelectedItem(item);
    if (locations.pathname === "/dashboard")
      window.location.replace("/dashboard#productsandservice");
    else
      history("/dashboard", {
        state: { anchorId: "/dashboard#productsandservice" },
      });
  };
  const selectedItemStyle = (
    item: string,
    item1?: string,
    item2?: string
  ): string => {
    let itemStyle = "";
    if (
      item === selectedItem ||
      item1 === selectedItem ||
      item2 === selectedItem
    )
      itemStyle = "sidebar-selected-background";
    return itemStyle;
  };

  const onDashboardClick = (): void => {
    onClickItem("dashboard");
  };
  const onClickContactButton = (): void => {
    window.open(CONTACT_SYNGENTA_UK, "_blank");
  };
  return (
    <div className="sidebar_collapse">
      <div
        className={`sidebar-icon-style ${selectedItemStyle("dashboard")}`}
        id="dashboard"
      >
        {selectedItem === "dashboard" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_dashboard"
          className={`mf-btn-img ${selectedItemStyle("dashboard")}`}
          onClick={onDashboardClick}
        >
          <img
            src={dashboardIcon}
            alt="icon"
            className="collapse-menu-margin"
          />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.DASHBOARD)}
        </div>
      </div>
      <div className={`sidebar-icon-style ${selectedItemStyle("mapview")}`}>
        {selectedItem === "mapview" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_mapview"
          className={`mf-btn-img ${selectedItemStyle("mapview")}`}
          onClick={() => {
            onClickItem("mapview");
          }}
        >
          <img src={mapviewIcon} alt="icon" className="collapse-menu-margin" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.MAP_VIEW)}
        </div>
      </div>

      <div className={`sidebar-icon-style ${selectedItemStyle("myoffers")}`}>
        {selectedItem === "myoffers" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_myoffers"
          className={`mf-btn-img ${selectedItemStyle("myoffers")}`}
          onClick={() => {
            onClickMyOffers("myoffers");
          }}
        >
          <img src={myoffersIcon} alt="icon" className="collapse-menu-margin" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.MY_OFFERS)}
        </div>
      </div>
      <div
        className={`sidebar-icon-style ${selectedItemStyle(
          "products-and-services"
        )}`}
      >
        {selectedItem === "products-and-services" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_productandservices"
          className={`mf-btn-img ${selectedItemStyle("products-and-services")}`}
          onClick={() => {
            onClickProductsAndServices("products-and-services");
          }}
        >
          <img
            src={productsAndServicesIcon}
            alt="icon"
            className="collapse-menu-margin"
          />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.NEW_PRODUCTS_AND_SERVICES)}
        </div>
      </div>
      <div className="sidebar-icon-style">
        {selectedItem === "contacts" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          className="mf-btn-img"
          data-testid="collapse_contacts"
          onClick={onClickContactButton}
        >
          <img src={contactsIcon} alt="icon" className="collapse-menu-margin" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.CONTACT_SYNGENTA)}
        </div>
      </div>
    </div>
  );
};
export default CollapseSidebar;
