import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Divider } from "syngenta-digital-cropwise-react-ui-kit";
import UserApi from "../../core/api/userApi";
import type { ApplicationState } from "../../core/redux/reducers";
import { LOYALTY_PORTAL_LINK } from "../../core/constants/url";
import { setLoyalityPoints } from "../../core/redux/reducers/accountProfileSlice";
import ConstantKey from "../../core/locale/strings.json";
import partnershipLogo from "../../assets/icons/partnership-logo.svg";
import "./loyalty-points.less";

const LoyaltyPoints = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    if (ProfileDataSelector?.profileData?.email) {
      setLoader(true);
      UserApi.fetchLoyaltiyPoints(ProfileDataSelector?.profileData?.email)
        .then((response: any) => {
          if (
            !Number.isNaN(
              Number(response?.data?.loyalty_program_member__balance)
            )
          ) {
            const loyaltypoin = new Intl.NumberFormat("en-IN", {
              maximumSignificantDigits: 9,
            }).format(Number(response?.data?.loyalty_program_member__balance));
            dispatch(setLoyalityPoints(loyaltypoin));
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoader(false);
        });
    }
  }, [ProfileDataSelector?.profileData]);
  const onClickViewMore = (): void => {
    window.open(LOYALTY_PORTAL_LINK, "_blank");
  };
  return (
    <div className="loyalty-points-container">
      <Card className="loyalty-card loyalty-points-rows">
        <div className="loyalty-card-content">
          <div className="loyalty-card-header">
            <div className="loyalty-program-title">
              <img
                src={partnershipLogo}
                className="tag-icon"
                alt="loyalty-logo"
              />
              <div className="mf-ml-10">
                <div className="loyalty-points-title">
                  {translate(ConstantKey.PARTNERSHIP_PLAN)}
                </div>
                <div className="loyalty-subtitle">
                  {translate(ConstantKey.PARTNERSHIP_PLAN_DES)}
                </div>
              </div>
            </div>
            <Divider type="vertical" className="loyalty-points-divider" />
            <div className="loyalty-points">
              {loader ? (
                <div className="mf-small-loader"></div>
              ) : (
                <>
                  <div className="loyalty-your-points">
                    {translate(ConstantKey.YOUR_POINTS)}
                  </div>
                  <div className="loyalty-user-points">
                    {ProfileDataSelector?.loyalityPoints} pts
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="loyalty-btn-view-col ">
          <button
            className="loyalty-btn-view-more mf-cursor-pointer"
            onClick={onClickViewMore}
          >
            {translate(ConstantKey.VIEW_MORE)}
          </button>
        </div>
      </Card>
    </div>
  );
};
export default LoyaltyPoints;
