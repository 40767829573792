import ConstantKey from "../../core/locale/strings.json";

import type { PasswordStrengthDTO } from "../types/authentication.dto";
import type {
  CountryCodeKeyDTO,
  MapCoordinatesDTO,
  OptionDto,
} from "../types/shared.dto";

export const myFarmOptions: OptionDto[] = [
  { label: "Fewer than 10 Hectares", value: "Fewer than 10 Hectares" },
  { label: "10-100 Hectares", value: "10-100 Hectares" },
  { label: "100-500 Hectares", value: "100-500 Hectares" },
  { label: "500-1000 Hectares", value: "500-1000 Hectares" },
  { label: "Greater than 1000 Hectares", value: "Greater than 1000 Hectares" },
];

export const myCropsOptions: OptionDto[] = [
  { label: "Corn", value: "Corn" },
  { label: "Soybeans", value: "Soybeans" },
  { label: "Wheat", value: "Wheat" },
  { label: "Rice", value: "Rice" },
  { label: "Potatoes", value: "Potatoes" },
  { label: "Sugar Cane", value: "Sugar Cane" },
];

export const jobTitlesOptions: OptionDto[] = [
  { label: "Grower", value: "GROWER" },
  { label: "Manager", value: "MANAGER" },
  { label: "Syngenta Internal", value: "SYNGENTA_INTERNAL" },
  { label: "Dealer", value: "DEALER" },
  { label: "Consultant", value: "CONSULTANT" },
  { label: "Agronomist", value: "AGRONOMIST" },
  { label: "Sales Representative", value: "SALES_REPRESENTATIVE" },
  { label: "Field Technician", value: "FIELD_TECHNICIAN" },
  { label: "Other", value: "OTHER" },
];

export const PASSWORD_STRENGTH_INFO: PasswordStrengthDTO = {
  yellow: {
    color: "#e99921",
    message: ConstantKey.FAIR,
  },
  red: {
    color: "#eb4b4b",
    message: ConstantKey.WEAK,
  },
  green: {
    color: "#14803c",
    message: ConstantKey.STRONG,
  },
  grey: {
    color: "#a3a9b9",
    message: ConstantKey.TOO_SHORT,
  },
};

export const languageLabels: Record<string, string> = {
  en: ConstantKey.ENGLISH,
};

export const DefLocByCountry: Record<CountryCodeKeyDTO, MapCoordinatesDTO> = {
  PL: {
    lat: 52.1246099075455,
    lng: 19.30063630556,
  },
  HU: {
    lat: 47.0602667471763,
    lng: 19.4993490091469,
  },
  IN: {
    lat: 22.1991660760527,
    lng: 78.476681027237,
  },
  US: {
    lat: 39.3812661305678,
    lng: -97.9222112121185,
  },
  BR: {
    lat: -9.58890301712257,
    lng: -51.6197890205486,
  },
  ES: {
    lat: 41.2948556295683,
    lng: -4.05568501525488,
  },
  DE: {
    lat: 51.1334813439932,
    lng: 10.0183432948567,
  },
  FR: {
    lat: 47.8249046208979,
    lng: 2.61878695312962,
  },
  AR: {
    lat: -36.252002,
    lng: -63.954193,
  },
  GB: {
    lat: 53.958332,
    lng: -2.080278,
  },
};
