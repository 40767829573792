import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  type DefaultLocationDTO,
  type CountryAPIProps,
  type ProfileInitialState,
} from "../../types/accountProfile.dto";
import { INITIAL_PROFILE_DATA } from "../../enum/profile";
import { type MapboxCoordinatesDTO } from "../../types/mapBox.dto";

const initialState: ProfileInitialState = {
  loading: false,
  dashboardLoader: false,
  profileData: INITIAL_PROFILE_DATA,
  language: "en",
  countriesData: [],
  isValidUser: false,
  isGrowerExist: false,
  userLocation: "",
  defaultOrgId: null,
  defaultPropertyId: null,
  defaultPropertyCoordinates: { lat: 0, lng: 0 },
  defaultLocation: null,
  refreshUserData: false,
  loyalityPoints: null,
  userPlans: {},
  passwordRecoveryDetails: null,
};

export const accountProfileSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    reset: () => initialState,
    setProfileDataLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setDashboardLoader: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        dashboardLoader: action.payload,
      };
    },
    setValidUser: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isValidUser: action.payload,
      };
    },
    setUserPlans: (state, action) => {
      return {
        ...state,
        userPlans: { ...action.payload },
      };
    },
    setIsGrowerExist: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isGrowerExist: action.payload,
      };
    },
    setProfileData: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        profileData: action.payload,
      };
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        language: action.payload,
      };
    },
    setDefaultPropertyId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        defaultPropertyId: action.payload,
      };
    },
    setDefaultOrgId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        defaultOrgId: action.payload,
      };
    },
    setCountriesData: (state, action: PayloadAction<CountryAPIProps[]>) => {
      return {
        ...state,
        countriesData: action.payload,
      };
    },
    setUserLocation: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        userLocation: action.payload,
      };
    },
    setDefaultPropertyCoordinates: (
      state,
      action: PayloadAction<MapboxCoordinatesDTO>
    ) => {
      return {
        ...state,
        defaultPropertyCoordinates: action.payload,
      };
    },
    setDefaultLocation: (state, action: PayloadAction<DefaultLocationDTO>) => {
      return {
        ...state,
        defaultLocation: action.payload,
      };
    },
    setRefreshUserData: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        refreshUserData: action.payload,
      };
    },
    setLoyalityPoints: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loyalityPoints: action.payload,
      };
    },
    setPasswordRecoveryDetails: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        passwordRecoveryDetails: action.payload,
      };
    },
  },
});

export const {
  reset,
  setProfileDataLoading,
  setDashboardLoader,
  setProfileData,
  setLanguage,
  setCountriesData,
  setValidUser,
  setDefaultOrgId,
  setIsGrowerExist,
  setUserLocation,
  setDefaultPropertyId,
  setDefaultPropertyCoordinates,
  setDefaultLocation,
  setRefreshUserData,
  setLoyalityPoints,
  setUserPlans,
  setPasswordRecoveryDetails,
} = accountProfileSlice.actions;
export default accountProfileSlice.reducer;
